import { default as displayq2Yq9eaPX1Meta } from "/app/pages/display.vue?macro=true";
import { default as _91slug_935xGnk5JrpsMeta } from "/app/pages/events/[slug].vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91slug_93iP8xUx47HeMeta } from "/app/pages/orgs/[slug].vue?macro=true";
import { default as indexF331mkLKAPMeta } from "/app/pages/orgs/index.vue?macro=true";
import { default as orientationF77HeihXBPMeta } from "/app/pages/orientation.vue?macro=true";
import { default as _91id_93G8CPKk2GFFMeta } from "/app/pages/portal/event/[id].vue?macro=true";
import { default as create6fEH8kI0rlMeta } from "/app/pages/portal/event/create.vue?macro=true";
import { default as index4bnDMlRibmMeta } from "/app/pages/portal/event/index.vue?macro=true";
import { default as indexpSPtK4vy5gMeta } from "/app/pages/portal/index.vue?macro=true";
import { default as _91id_93k9TpSyzJv9Meta } from "/app/pages/portal/org/[id].vue?macro=true";
import { default as indexfQwB39UwIWMeta } from "/app/pages/portal/org/index.vue?macro=true";
import { default as _91id_93Atk57NOIg0Meta } from "/app/pages/portal/venue/[id].vue?macro=true";
import { default as indexNpY8qTnRSrMeta } from "/app/pages/portal/venue/index.vue?macro=true";
import { default as _91id_93ROGs7Dl9ycMeta } from "/app/pages/sso/event/[id].vue?macro=true";
import { default as createR83Qk5HC03Meta } from "/app/pages/sso/event/create.vue?macro=true";
import { default as indexcrfRHasxn6Meta } from "/app/pages/sso/event/index.vue?macro=true";
import { default as index59YwgloyDSMeta } from "/app/pages/sso/index.vue?macro=true";
import { default as _91id_93zMimKl2FDgMeta } from "/app/pages/sso/org/[id].vue?macro=true";
import { default as createSEFv9onrhIMeta } from "/app/pages/sso/org/create.vue?macro=true";
import { default as indexYE59H62UtPMeta } from "/app/pages/sso/org/index.vue?macro=true";
import { default as settingsJAiUaKENQuMeta } from "/app/pages/sso/settings.vue?macro=true";
import { default as _91id_93aDOUZ5PUKMMeta } from "/app/pages/sso/venue/[id].vue?macro=true";
import { default as index7tBsL4RkuvMeta } from "/app/pages/sso/venue/index.vue?macro=true";
export default [
  {
    name: "display",
    path: "/display",
    component: () => import("/app/pages/display.vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orgs-slug",
    path: "/orgs/:slug()",
    component: () => import("/app/pages/orgs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "orgs",
    path: "/orgs",
    component: () => import("/app/pages/orgs/index.vue").then(m => m.default || m)
  },
  {
    name: "orientation",
    path: "/orientation",
    component: () => import("/app/pages/orientation.vue").then(m => m.default || m)
  },
  {
    name: "portal-event-id",
    path: "/portal/event/:id()",
    component: () => import("/app/pages/portal/event/[id].vue").then(m => m.default || m)
  },
  {
    name: "portal-event-create",
    path: "/portal/event/create",
    component: () => import("/app/pages/portal/event/create.vue").then(m => m.default || m)
  },
  {
    name: "portal-event",
    path: "/portal/event",
    component: () => import("/app/pages/portal/event/index.vue").then(m => m.default || m)
  },
  {
    name: "portal",
    path: "/portal",
    component: () => import("/app/pages/portal/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-org-id",
    path: "/portal/org/:id()",
    component: () => import("/app/pages/portal/org/[id].vue").then(m => m.default || m)
  },
  {
    name: "portal-org",
    path: "/portal/org",
    component: () => import("/app/pages/portal/org/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-venue-id",
    path: "/portal/venue/:id()",
    component: () => import("/app/pages/portal/venue/[id].vue").then(m => m.default || m)
  },
  {
    name: "portal-venue",
    path: "/portal/venue",
    component: () => import("/app/pages/portal/venue/index.vue").then(m => m.default || m)
  },
  {
    name: "sso-event-id",
    path: "/sso/event/:id()",
    component: () => import("/app/pages/sso/event/[id].vue").then(m => m.default || m)
  },
  {
    name: "sso-event-create",
    path: "/sso/event/create",
    component: () => import("/app/pages/sso/event/create.vue").then(m => m.default || m)
  },
  {
    name: "sso-event",
    path: "/sso/event",
    component: () => import("/app/pages/sso/event/index.vue").then(m => m.default || m)
  },
  {
    name: "sso",
    path: "/sso",
    component: () => import("/app/pages/sso/index.vue").then(m => m.default || m)
  },
  {
    name: "sso-org-id",
    path: "/sso/org/:id()",
    component: () => import("/app/pages/sso/org/[id].vue").then(m => m.default || m)
  },
  {
    name: "sso-org-create",
    path: "/sso/org/create",
    component: () => import("/app/pages/sso/org/create.vue").then(m => m.default || m)
  },
  {
    name: "sso-org",
    path: "/sso/org",
    component: () => import("/app/pages/sso/org/index.vue").then(m => m.default || m)
  },
  {
    name: "sso-settings",
    path: "/sso/settings",
    component: () => import("/app/pages/sso/settings.vue").then(m => m.default || m)
  },
  {
    name: "sso-venue-id",
    path: "/sso/venue/:id()",
    component: () => import("/app/pages/sso/venue/[id].vue").then(m => m.default || m)
  },
  {
    name: "sso-venue",
    path: "/sso/venue",
    component: () => import("/app/pages/sso/venue/index.vue").then(m => m.default || m)
  }
]