<script setup lang="ts">
import type {NuxtError} from '#app';

const props = defineProps<{ error: NuxtError }>()
const toLogin = () => {
  window.location.replace("/api/auth/login")
}

console.log(props.error)
</script>

<template>
  <div v-if="error.statusCode == 401"
       class="absolute w-full h-full flex flex-col md:flex-row justify-center items-center space-x-4">
    <img class="w-64 md:w-96"
         src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExcGRrNmZndHhudHBhNGU4anJxcWZzYTZteTAyd3pqNTFycDF0ZGVtZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/RKNYxAq5w5O8ASk9F8/giphy.gif"
         alt="">
    <div class="px-4 text-center md:text-left">
      <p class="text-5xl md:text-7xl font-bold">Error 401</p>
      <p class="text-center text-2xl md:text-3xl font-semibold mt-2 mb-4">You're not supposed to be here...</p>
      <div class="text-xl md:text-2xl font-semibold" @click="$router.back()">
        <button class="border rounded-xl px-5 py-1 border-gray-400">Back</button>
      </div>
    </div>
  </div>
  <div v-if="error.statusCode == 404"
       class="absolute w-full h-full flex flex-col md:flex-row justify-center items-center space-x-4">
    <img class="w-64 md:w-72" src="https://media.tenor.com/6QWwWWSGPLcAAAAe/what-pusheen.png" alt="">
    <div class="px-8 text-center md:text-left">
      <p class="text-5xl md:text-7xl font-bold">Error 404</p>
      <p class="text-center text-2xl md:text-3xl font-semibold my-2 mb-4">We couldn't find what you were looking
        for...</p>
      <div class="text-xl md:text-2xl font-semibold" @click="$router.back()">
        <button class="border rounded-xl px-5 py-1 border-gray-400">Back</button>
      </div>
    </div>
  </div>
  <div v-if="error.statusCode == 403"
       class="absolute w-full h-full flex flex-col md:flex-row justify-center items-center space-x-4">
    <img class="w-64 md:w-72"
         src="https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExcWNydzBvcGRxM2NsNW9qd21lbzN0YnJzejYzdTRoNXNvaHBmbTBjMCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/9oa3sE4IdWbqO61WGT/giphy.gif"
         alt="">
    <div class="px-4 text-center md:text-left">
      <p class="text-5xl md:text-7xl font-bold">Error 403</p>
      <p class="text-2xl md:text-3xl font-semibold mt-2 mb-4 max-w-[720px]">You must be Logged In if you're not using
        MCKL Wifi.</p>
      <a class="text-xl md:text-2xl font-semibold" @click="toLogin">
        <button class="border rounded-xl px-5 py-1 border-gray-400">Login</button>
      </a>
    </div>
  </div>
</template>